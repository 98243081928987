define("discourse/plugins/discourse-policy/pre-initializers/policy-user-options", ["exports", "discourse/controllers/preferences/emails", "discourse/lib/plugin-api"], function (_exports, _emails, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SEND_EMAIL_NOTIFICATIONS_FIELD = "policy_email_frequency";
  var _default = _exports.default = {
    name: "policy-user-options",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8.7", api => {
        const siteSettings = container.lookup("service:site-settings");
        if (siteSettings.policy_enabled) {
          api.addSaveableUserOptionField(SEND_EMAIL_NOTIFICATIONS_FIELD);
        }
      });
      _emails.default.reopen({
        init() {
          this._super(...arguments);
          this.saveAttrNames.push(SEND_EMAIL_NOTIFICATIONS_FIELD);
        }
      });
    }
  };
});